import React, { useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { NavContext } from "../../context/NavContext"
import PropTypes from "prop-types"

// eslint-disable-next-line react/display-name
const AnimationLink = React.forwardRef((props, ref) => {
  const { isNavOpen } = useContext(NavContext)

  const {
    activeClassName,
    ariaLabel,
    children,
    className,
    id,
    onClick,
    state,
    to,
    style,
  } = props
  return (
    <TransitionLink
      activeClassName={activeClassName}
      aria-label={ariaLabel}
      className={className}
      title={ariaLabel}
      ref={ref}
      exit={{
        zIndex: 1,
        length: 1,
        delay: isNavOpen ? 0.53 : 0.2,
        state: {
          fromAnimLink: false,
          ...state,
        },
      }}
      entry={{
        zIndex: 2,
        length: 1,
        delay: isNavOpen ? 0.5 : 0.25,
        state: {
          fromAnimLink: true,
          ...state,
        },
        trigger: () => {
          window.scroll(0, 0)
        },
      }}
      state={{ fromAnimLink: true, ...state }}
      id={id}
      partiallyActive
      to={to}
      onClick={onClick}
      style={style}
    >
      {children}
    </TransitionLink>
  )
})

export default AnimationLink

AnimationLink.propTypes = {
  activeClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.string,
  to: PropTypes.string,
}.isRequired
