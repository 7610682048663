/* eslint-disable react/prop-types */
import React from "react"
import ThemeWrapper from "./globalstyle"
import Navigation from "./nav/Navigation"
import { AnimatePresence } from "framer-motion"
import { useSiteQuery } from "../queries/SITE_QUERIES"
import { TransitionPortal } from "gatsby-plugin-transition-link"

const Layout = props => {
  const { location, children, pageContext } = props
  const data = useSiteQuery()

  return (
    <ThemeWrapper>
      <TransitionPortal>
        <Navigation location={location} data={data} pageContext={pageContext} />
      </TransitionPortal>
      <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
    </ThemeWrapper>
  )
}

export default Layout
