import React from "react"
import { Container, Row, Col } from "react-grid-system"
import Img from "gatsby-image"

const CaseStudyFullWidthImage = ({ bts4, bts5 }) => (
  <Container fluid style={{ width: `100vw`, padding: 0 }}>
    <Row gutterWidth={0}>
      <Col xs={12}>
        {bts4 && <Img fluid={bts4} />}
        {bts5 && <Img fluid={bts5} />}
      </Col>
    </Row>
  </Container>
)

export default CaseStudyFullWidthImage
