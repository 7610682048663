import { css } from "styled-components"

export const size = {
  xs: 576,
  s: 768,
  m: 992,
  l: 1200,
  xl: 2000,
}

export const vert = {
  xs: 800,
}
export const above = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const belowVert = Object.keys(vert).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-height: ${vert[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})
