import React, { useState, useEffect, useContext } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"
import Img from "gatsby-image"
import styled from "styled-components"
import LightboxModal from "../Modal/LightboxModal"
import { NavContext } from "../../context/NavContext"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import { rhythm } from "../../../config/typography"
import { below } from "../../../config/utilities"

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const FramerLightbox = ({ images, modalImagePadding }) => {
  const { isModalOpen, setModalOpen } = useContext(NavContext)

  const [[page, direction], setPage] = useState([0, 0])

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page)

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }
  const handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (isModalOpen) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (imageIndex > images.length - 1) {
          paginate(1)
        } else {
          paginate(-1)
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        paginate(1)
      }
      if (keyCode === 27) {
        // Escape key
        setModalOpen(false)
        window.scrollTo(0, document.body.scrollHeight)
      }
    }
  }
  useEffect(() => {
    window.addEventListener(`keyup`, handleKeyUp, false)
    return () => {
      window.removeEventListener(`keyup`, handleKeyUp, false)
    }
  }, [isModalOpen, imageIndex])

  return (
    <TransitionPortal level="top">
      <LightboxModal
        on={isModalOpen}
        closeModal={() => setModalOpen(false)}
        useCloseButton
        useModalCard
        bgColor="rgba(17, 24, 31,.9)"
      >
        <AnimatePresence initial={false} custom={direction}>
          <ImageDiv
            className="innerwrapmed"
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 200 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              if (offset.x < 0) {
                paginate(1)
              } else if (offset.x > 0) {
                paginate(-1)
              }
            }}
          >
            <Img
              fluid={images[imageIndex]}
              style={{ width: "100%", pointerEvents: "none" }}
            />
          </ImageDiv>
        </AnimatePresence>
        <div className="next" onClick={() => paginate(1)}>
          {"‣"}
        </div>
        <div className="prev" onClick={() => paginate(-1)}>
          {"‣"}
        </div>
      </LightboxModal>
    </TransitionPortal>
  )
}

const MotionGatsbyImg = styled(motion.custom(Img))``
/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */

const ImageDiv = styled(motion.div)`
  width: 100%;
  position: absolute;
  padding: ${rhythm(4)};
  ${below.s`
  padding: ${rhythm(3.25)};
  `}
  ${below.xs`
  padding: ${rhythm(2.5)};
  `}
`
export default FramerLightbox
