import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import PropTypes from "prop-types"
import { DDHeader } from "../../elements"
import Video from "../../elements/Video"
import { rhythm } from "../../../config/typography"
import { FaGlobe } from "react-icons/fa"
import { motion } from "framer-motion"
const imagestyle = {
  position: `absolute`,
  overflow: `hidden`,
  bottom: 0,
  top: 0,
  right: 0,
  left: 0,
  width: `100%`,
  height: `100%`,
  zIndex: `-10`,
  opacity: `.5`,
  mixBlendMode: `multiply`,
}
const CaseStudyBanner = ({
  clientName,
  homehero,
  projectTitle,
  videoSrcURL,
  widescreen,
  clientSite,
}) => {
  const vidDisplay = videoSrcURL != null

  let banner
  if (vidDisplay) {
    banner = (
      <>
        <Video
          videoSrcURL={videoSrcURL}
          videoTitle={projectTitle}
          widescreen={widescreen}
        />

        <GridItem padTop="0">
          <DDHeader lineOne={clientName} lineTwo={projectTitle} />
          {clientSite && (
            <ClientLink
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              title={`Visit ${clientName}'s Website`}
              target="_blank"
              href={clientSite}
              rel="noopener noreferrer"
            >
              <FaGlobe />
              <h5>{clientName}'s Website</h5>
              {` `}
            </ClientLink>
          )}
        </GridItem>

        <Img
          fluid={homehero}
          objectPosition="50% 50%"
          objectFit="cover"
          style={imagestyle}
        />
      </>
    )
  } else {
    banner = (
      <>
        <GridItem padTop={`${rhythm(1.5)}`}>
          <DDHeader lineOne={clientName} lineTwo={projectTitle} />
          {clientSite && (
            <ClientLink
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              title={`Visit ${clientName}'s Website`}
              target="_blank"
              href={clientSite}
              rel="noopener noreferrer"
            >
              <FaGlobe />
              <h5>{clientName}'s Website</h5>
              {` `}
            </ClientLink>
          )}
        </GridItem>

        <Img
          fluid={homehero}
          objectPosition="50% 50%"
          objectFit="cover"
          style={imagestyle}
        />
      </>
    )
  }

  return <DDFeature>{banner}</DDFeature>
}

export default CaseStudyBanner

const ClientLink = styled(motion.a)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  opacity: 0.5;
  padding-top: ${rhythm(1 / 2)};
  padding-bottom: ${rhythm(1 / 2)};
  color: ${props => props.theme.brand.primarylitest};
  h5 {
    margin: 0;
    margin-left: ${rhythm(1 / 2)};
  }
`
const GridItem = styled.div`
  margin: auto;
  padding-top: ${props => props.padTop || `0px`};
`
export const DDFeature = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.brand.primaryfade};
  text-align: center;
  padding: ${rhythm(2.5)} 0 !important;
  min-height: 400px;
`

CaseStudyBanner.propTypes = {
  clientName: PropTypes.string.isRequired,
  projectTitle: PropTypes.string.isRequired,
  videoSrcURL: PropTypes.string,
  widescreen: PropTypes.bool,
}

CaseStudyBanner.defaultProps = {
  videoSrcURL: null,
}
