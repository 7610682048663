import React, { createContext, useState } from "react"
import useScrollDirection from "../../src/hooks/useScrollDirection"
import { useViewportScroll } from "framer-motion"

const defaultValues = {
  isNavOpen: false,
  setNavOpen: () => {},
  direction: `up`,
}

export const NavContext = createContext(defaultValues)

export const NavProvider = ({ children }) => {
  const [isNavOpen, setNavOpen] = useState(false)
  const [isLogoAnimComplete, setLogoAnimComplete] = useState(false)
  const [isWorkshopBlogPost, setWorkshopBlogPost] = useState(false)
  const [navigationColor, setNavigationColor] = useState(`#ffffff`)

  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalAnimComplete, setModalAnimComplete] = useState(false)
  const [setNavSVG, isSetNavSVG] = useState(false)
  const [locationSlug, setLocationSlug] = useState(``)
  const { scrollY } = useViewportScroll()
  const yPos = scrollY.current
  const direction = useScrollDirection()
  const navDark = `#2c3e50`
  const navLite = `#ffffff`
  const [isTransitioning, setIsTransitioning] = useState(false)

  return (
    <NavContext.Provider
      value={{
        locationSlug,
        setLocationSlug,
        navDark,
        navLite,
        yPos,
        direction,
        isNavOpen,
        setNavOpen,
        isLogoAnimComplete,
        setLogoAnimComplete,
        isWorkshopBlogPost,
        setWorkshopBlogPost,
        navigationColor,
        setNavigationColor,
        isModalOpen,
        setModalOpen,
        isModalAnimComplete,
        setModalAnimComplete,
        isTransitioning,
        setIsTransitioning,
        setNavSVG,
        isSetNavSVG,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}
