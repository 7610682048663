const React = require(`react`)
const { NavProvider } = require(`./src/context/NavContext`)
const { ScreenClassProvider, setConfiguration } = require(`react-grid-system`)
const Layout = require(`./src/components/Layout`).default
require(`intersection-observer`)
const { TransitionState } = require(`gatsby-plugin-transition-link`)

exports.wrapRootElement = ({ element }) => {
  setConfiguration({ breakpoints: [576, 769, 992, 1200] })

  return (
    <NavProvider>
      <ScreenClassProvider>{element}</ScreenClassProvider>
    </NavProvider>
  )
}
exports.wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

exports.onInitialClientRender = () => {
  require(`typeface-montserrat`)
  require(`typeface-raleway`)
  require(`typeface-teko`)
}

exports.onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `We've made some tweaks to the PAKD Media website. Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}

exports.onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
