import React from "react"
import styled, { css } from "styled-components"
import AnimationLink from "../components/nav/AnimationLink"
import PropTypes from "prop-types"

export const Button = ({ secondary, linkTo, children, whitehover }) => {
  let button
  if (secondary) {
    button = (
      <ButtonInternal className="buttonint" secondary to={linkTo}>
        <span>{children}</span>
      </ButtonInternal>
    )
  } else {
    button = (
      <ButtonInternal to={linkTo}>
        <span>{children}</span>
      </ButtonInternal>
    )
  }
  return <React.Fragment>{button}</React.Fragment>
}
Button.defaultProps = {
  secondary: false,
}

Button.propTypes = {
  secondary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tertiary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.string.isRequired,
}

export const ButtonInt = ({ secondary, children, tertiary }) => {
  let button
  if (secondary) {
    button = (
      <ButtonA secondary>
        <span>{children}</span>
      </ButtonA>
    )
  } else if (tertiary) {
    button = (
      <ButtonA tertiary>
        <span>{children}</span>
      </ButtonA>
    )
  } else {
    button = (
      <ButtonA>
        <span>{children}</span>
      </ButtonA>
    )
  }
  return <React.Fragment>{button}</React.Fragment>
}

ButtonInt.propTypes = {
  secondary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tertiary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.string.isRequired,
}
export const ButtonInternal = styled(AnimationLink)`
  display: flex;
  padding: 0.5rem 1.33rem;
  margin: 0.5rem 1rem;
  position: relative;
  background-clip: text;
  border: 1px solid ${props => props.theme.colors.litegrey};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: ${props => props.theme.brand.prptealrev};
  transition: ${props => props.theme.transitions.circ};
  width: 10rem;
  margin: auto;
  z-index: 100;
  span {
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    margin: 0 auto;
  }
  &:after {
    content: "";
    height: 0%;
    width: 100%;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    transition: ${props => props.theme.transitions.circ};
  }
  &:before {
    content: "";
    height: 100%;
    width: 100%;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform-origin: bottom center;
    transform: scaleY(0);
    z-index: -5;
    transition: ${props => props.theme.transitions.circ};
  }
  &:hover {
    background-position-y: 0;
    -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    transition: ${props => props.theme.transitions.circ};
    -webkit-text-fill-color: ${props =>
      props.whitehover ? `white` : `transparent`};
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: ${props => props.theme.brand.prptealrev};
    }
    :after {
      transition: ${props => props.theme.transitions.circ};
      height: 0%;
    }
    :before {
      transform: scaleY(1);
    }
  }

  /* Gradient background button with hover to transparent background. */
  ${props =>
    props.secondary &&
    css`
      background-image: linear-gradient(
        135deg,
        #03e1b7 0%,
        #427dd4 50%,
        #6940e7 100%
      );
      background-position-y: 2em;
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: white;
      border: 2px solid white;
      text-align: center;
      cursor: pointer;
      position: relative;
      -webkit-transition: all 0.75s cubic-bezier(0.15, 0.65, 0.4, 1);
      transition: all 0.75s cubic-bezier(0.15, 0.65, 0.4, 1);
      border: 1px solid transparent;

      &:after {
        content: "";
        height: 100%;
        width: 100%;
        background: ${props => props.theme.brand.prptealrev};
        background-size: cover;

        top: 0;
        left: 0;
        z-index: -5;
        transition: ${props => props.theme.transitions.circ};
      }
      a {
        color: white;
      }
      &:hover {
        border: 1px solid ${props => props.theme.colors.midgrey};
        -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        color: rgba(255, 255, 255, 1);
        transition: ${props => props.theme.transitions.circ};

        &:after {
          height: 0%;
          transition: ${props => props.theme.transitions.circ};
        }
      }
    `}/* Gradient background button with hover to transparent background. */
`
export const ButtonA = styled.a`
  display: inline-flex;
  padding:0.5rem 1.33rem;
  margin: 0.5rem 1rem;
  position: relative;
  background-clip: text;
  border:1px solid transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  background-image: ${props => props.theme.brand.prptealrev};
  transition: ${props => props.theme.transitions.circ};
  z-index:1000000000;
  span {
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    margin: 0 auto;
  }
  &:after {
    content: "";
    height: 0%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    transition: ${props => props.theme.transitions.circ};
  }

  &:hover {
    background-image: ${props => props.theme.brand.prptealrev};
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-position-y: 0;
    background-clip: text;
    -webkit-text-fill-color: ${props => props.theme.brand.primary};
    -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    transition: ${props => props.theme.transitions.circ};
    :after {
      transition: ${props => props.theme.transitions.circ};
      height: 100%;
    }
  }

  /* Gradient background button with hover to transparent background. */
  ${props =>
    props.secondary &&
    css`
      background-image: ${props => props.theme.brand.prptealrev};
      &:before {
        content: "";
        height: 100%;
        width: 100%;
        background: white;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform-origin: bottom center;
        transform: scaleY(0);
        z-index: -5;
        transition: ${props => props.theme.transitions.circ};
      }
      &:after {
        content: "";
        height: 100%;
        width: 100%;
        background: ${props => props.theme.brand.prptealrev};
        top: 0;
        left: 0;
        z-index: -5;
        transition: ${props => props.theme.transitions.circ};
      }

      &:hover {
        border: 1px solid white;
        -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        color: ${props => props.theme.brand.primary};
        background-image: transparent;
        transition: ${props => props.theme.transitions.circ};
        span {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: ${props => props.theme.brand.prptealrev};
        }

        &:after {
          height: 0%;
          transition: ${props => props.theme.transitions.circ};
        }
        :before {
          transform: scaleY(1);
        }
      }
    `} /* Gradient background button with hover to transparent background. */


  ${props =>
    props.tertiary &&
    css`
      &:hover {
        -webkit-text-fill-color: white;
        transition: ${props => props.theme.transitions.circ};
      }
    `}
`
export const TransitionButton = ({ secondary, linkTo, children, tertiary }) => (
  <TransitionButtonWrap secondary={secondary} tertiary={tertiary} to={linkTo}>
    <span>{children}</span>
  </TransitionButtonWrap>
)

ButtonInt.defaultProps = {
  tertiary: `0`,
}

export const TransitionButtonWrap = styled(AnimationLink)`
  display: inline-flex;
  padding:0.5rem 1.33rem;
  margin: 0.5rem 1rem;
  position: relative;
  background-clip: text;
  border:1px solid transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  background-image: ${props => props.theme.brand.prptealrev};
  transition: ${props => props.theme.transitions.circ};
  z-index:100;
  span{
    text-transform:uppercase;
    font-style: normal;
    margin:0 auto;
  }

  &:after {
    content: "";
    height: 0%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    transition: ${props => props.theme.transitions.circ};
  }

  &:hover {
    background-image: ${props => props.theme.brand.prptealrev};
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-position-y: 0;
    background-clip: text;
    -webkit-text-fill-color: ${props => props.theme.brand.primary};
    -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    transition: ${props => props.theme.transitions.circ};
    :after {
      transition: ${props => props.theme.transitions.circ};
      height: 100%;
    }
  }

  /* Gradient background button with hover to transparent background. */
  ${props =>
    props.secondary &&
    css`
      background-image: ${props => props.theme.brand.prptealrev};

      &:after {
        content: "";
        height: 100%;
        width: 100%;
        background: ${props => props.theme.brand.prptealrev};
        top: 0;
        left: 0;
        z-index: -5;
        transition: ${props => props.theme.transitions.circ};
      }

      &:hover {
        border: 1px solid white;
        -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        color: ${props => props.theme.brand.primary};
        span {
          -webkit-text-fill-color: transparent;
        }

        background-image: transparent;
        transition: ${props => props.theme.transitions.circ};
        &:after {
          height: 0%;
          transition: ${props => props.theme.transitions.circ};
        }
      }
    `} /* Gradient background button with hover to transparent background. */


  ${props =>
    props.tertiary &&
    css`
      &:hover {
        -webkit-text-fill-color: white;
        transition: ${props => props.theme.transitions.circ};
      }
    `}
`

export const ContactButtons = ({ children, reset, form }) => {
  let button
  if (reset) {
    button = (
      <AsButton
        type="reset"
        ariaLabel="Reset Contact Form"
        secondary
        onClick={form.reset}
      >
        <h6>{children}</h6>
      </AsButton>
    )
  } else {
    button = (
      <AsButton type="submit" ariaLabel="Submit Contact Form" secondary>
        <h6>{children}</h6>
      </AsButton>
    )
  }
  return <React.Fragment>{button}</React.Fragment>
}
const AsButton = styled.button`
  display: inline-flex;
  padding: 0.5rem 1.33rem;
  margin: 0.5rem 1rem;
  position: relative;
  background-clip: text;
  border: 1px solid transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  background-image: ${props => props.theme.brand.prptealrev};
  transition: ${props => props.theme.transitions.circ};
  z-index: 100;
  h6 {
    margin: 0 auto;
  }

  &:after {
    content: "";
    height: 0%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    transition: ${props => props.theme.transitions.circ};
  }

  &:hover {
    background-image: ${props => props.theme.brand.prptealrev};
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-position-y: 0;
    background-clip: text;
    -webkit-text-fill-color: ${props => props.theme.brand.primary};
    -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
    transition: ${props => props.theme.transitions.circ};
    :after {
      transition: ${props => props.theme.transitions.circ};
      height: 100%;
    }
  }
  ${props =>
    props.secondary &&
    css`
      background-image: linear-gradient(
        135deg,
        #03e1b7 0%,
        #427dd4 50%,
        #6940e7 100%
      );
      background-position-y: 2em;
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: white;
      border: 2px solid white;
      text-align: center;
      cursor: pointer;
      position: relative;
      -webkit-transition: all 0.75s cubic-bezier(0.15, 0.65, 0.4, 1);
      transition: all 0.75s cubic-bezier(0.15, 0.65, 0.4, 1);
      border: 1px solid transparent;

      &:after {
        content: "";
        height: 100%;
        width: 100%;
        background: ${props => props.theme.brand.prptealrev};
        background-size: cover;
        top: 0;
        left: 0;
        z-index: -5;
        transition: ${props => props.theme.transitions.circ};
      }
      a {
        color: white;
      }
      &:hover {
        border: 1px solid ${props => props.theme.colors.midgrey};
        -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
        color: rgba(255, 255, 255, 1);
        transition: ${props => props.theme.transitions.circ};

        &:after {
          height: 0%;
          transition: ${props => props.theme.transitions.circ};
        }
      }
    `}/* Gradient background button with hover to transparent background. */
`
