import React from "react"
import { Container, Row, Col } from "react-grid-system"
import Img from "gatsby-image"

const CaseStudyTriImage = ({ frontmatter }) => (
  <Container fluid style={{ width: `100vw`, padding: 0 }}>
    <Row gutterWidth={0}>
      <Col md={4} xs={12}>
        <Img fluid={frontmatter.section1dd.childImageSharp.fluid} />
      </Col>
      <Col md={4} xs={12}>
        <Img fluid={frontmatter.section2dd.childImageSharp.fluid} />
      </Col>
      <Col md={4} xs={12}>
        <Img fluid={frontmatter.lightbox8.childImageSharp.fluid} />
      </Col>
    </Row>
    {frontmatter.vidA && (
      <Row>
        <video
          autoPlay
          muted
          playsInline
          loop
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          crossOrigin="anonymous"
          style={{ width: `100%`, height: `100%` }}
        >
          <source src={frontmatter.vidA} type="video/mp4" />
        </video>
      </Row>
    )}
  </Container>
)

export default CaseStudyTriImage
