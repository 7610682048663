import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { rhythm } from "../../config/typography"

const Video = ({ videoTitle, videoSrcURL, widescreen }) => (
  <div className="innerwrap" style={{ width: `100%`, height: `100%` }}>
    <Wrap widescreen={widescreen}>
      <iframe
        className="medshadow"
        title={videoTitle}
        src={videoSrcURL}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </Wrap>
  </div>
)

export default Video
const Wrap = styled.div`
  grid-area: Video;
  position: relative;
  padding: 42.19% 0 0 0;
  margin-top: ${rhythm(1)};
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0.85);
  }
  ${props =>
    props.widescreen &&
    css`
      padding: 56.25% 0 0 0;
    `}
`

Video.propTypes = {
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  widescreen: PropTypes.bool.isRequired,
}
