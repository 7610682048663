/* eslint-disable react/prop-types */
import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import reset from "../styles/reset"
import theme from "../../config/theme"

const GlobalStyle = createGlobalStyle`
  ${reset}
`

const ThemeWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyle />
      <>{children}</>
    </React.Fragment>
  </ThemeProvider>
)

export default ThemeWrapper
