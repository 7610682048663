import React, { useRef, useEffect, useContext } from "react"
import { useInView } from "react-intersection-observer"
import { NavContext } from "../../context/NavContext"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"

const DDVideo = ({ vidSrc, vidTitle }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false,
  })
  const videoEl = useRef()
  const { isModalOpen } = useContext(NavContext)
  const state = useTransitionState()

  useEffect(() => {
    if (videoEl.current) {
      const playPromise = videoEl.current.play()
      if (state.transitionStatus === `entered`) {
        if (inView) {
          videoEl.current.play()
        } else if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
            })
        } else if (state.transitionStatus === `exiting`) {
          videoEl.current.pause()
        }
      }
    }
  }, [state])

  useEffect(() => {
    if (isModalOpen) {
      videoEl.current.pause()
    } else {
      videoEl.current.play()
    }
  }, [isModalOpen])

  return (
    <div
      css={`
        object-fit: cover;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        * {
          object-fit: cover;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          min-width: 100%;
          min-height: 100%;
        }
      `}
      ref={ref}
    >
      <video
        autoPlay
        muted
        playsInline
        loop
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        ref={videoEl}
        title={vidTitle}
        aria-label={vidTitle}
        crossOrigin="anonymous"
      >
        <source src={vidSrc} title={vidTitle} type="video/mp4" />
      </video>
    </div>
  )
}

export default DDVideo
