import { css } from "styled-components"
import { below } from "../../config/utilities"
import theme from "../../config/theme"
import { rhythm } from "../../config/typography"
import favicon from "../images/icon.png"

const reset = css`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  * {
    -webkit-overflow-scrolling: touch;
  }
  body,
  html {
    margin: 0;
    padding: 0;
    pointer-events: all;
  }
  html {
    box-sizing: border-box;
    overflow: unset;
  }
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
  main {
    height: 100%;
    min-height: 100vh;
    margin: auto;
  }
  footer {
    width: 100%;
  }
  html {
    font-size: 16px;
    ${below.xs`
    font-size:14.5px;
    `}
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-rendering: optimizeLegibility;
      vertical-align: baseline;
    }
    h1 {
      font-family: "Raleway", sans-serif;
      font-weight: 900;
      text-transform: none;
    }
    h2 {
      font-family: "Raleway", sans-serif;
      font-weight: 800;
    }
    h3 {
      font-family: "Raleway", sans-serif;
      font-weight: 400;
      color: ${props => props.theme.brand.primary};
    }
    h4 {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      color: ${props => props.theme.brand.primary};
    }
    h5 {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
    }
    h6 {
      font-family: Teko !important;
      text-transform: uppercase;
      font-weight: 400;
      font-style: normal;
    }
    p {
      font-family: Montserrat;
      font-size: 0.875rem;
      font-weight: 400;
      margin: 0 auto;
      margin: 0 auto ${rhythm(1)} auto;
    }
  }
  pre code {
    background-color: ${theme.brand.primaryalt};
    color: white;
    border: 1px solid #999;
    display: block;
    padding: ${rhythm(1.666)};
    white-space: pre-wrap;
    margin-bottom: ${rhythm(1)};
  }
  a {
    color: ${theme.colors.litegrey};
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    &:hover,
    &:focus {
      transition: all 0.5s ease-in-out;
    }
    span {
      font-family: Teko;
      font-weight: 400;
    }
  }
  blockquote {
    border-left: 5px solid ${theme.colors.link_color};
    padding-left: 1rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-style: italic;
    p {
      line-height: 1.3 !important;
    }
  }
  [tabindex="-1"]:focus {
    outline: none !important;
  }
  [role="button"] {
    cursor: pointer;
  }
  a,
  area,
  button,
  [role="button"],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
    background-color: ${theme.colors.bg_color};
  }
  caption {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: ${theme.colors.body_color};
    text-align: center;
    caption-side: bottom;
  }
  th {
    text-align: left;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  input,
  button,
  select,
  textarea {
    line-height: inherit;
  }
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }
  textarea {
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  input[type="search"] {
    -webkit-appearance: none;
  }
  output {
    display: inline-block;
  }
  ::-webkit-scrollbar {
    width: ${theme.spacing.scrollbarwidth};
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.midgrey};
    outline: 1px solid ${theme.colors.grey};
    border-radius: 32px;
  }
  ::selection {
    color: ${theme.colors.white};
    background-color: ${theme.colors.link_color};
  }
  .maxwidth {
    width: 100vw !important;
    margin: 0 !important;
    padding: 0 !important;
    .proj1 {
      max-height: 500px;
    }
    * {
      width: 100%;
    }
  }
  .topnavroom {
    position: absolute;
    width: 100% !important;
  }
  .relative {
    position: relative;
  }
  .col-flex {
    height: 100%;
    align-items: center;
    display: flex;
  }
  .logo-link {
    height: 100%;
    margin: 0 auto;
    padding: ${rhythm(0.1)} 0;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      height: 100%;
      margin: 0 auto;
      max-height: 62px;
    }
    svg {
      height: 100%;
    }
  }
  #logo {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
  }
  .transitionmodal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    height: 100vh;
    z-index: 1000000000;
    display: flex;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: fit-content;
    z-index: 100000;
    display: flex;
  }
  .menubtn-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000000;
    height: 100%;
  }
  .menubtn-left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000000;
    height: 100%;
  }
  .navmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    background: ${theme.colors.primarydark};
    padding: ${rhythm(1)};
  }
  .modalcloser {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000000;
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  }

  .next,
  .prev {
    top: calc(50% - 20px);
    position: absolute;
    background: white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
    h3 {
      margin: 0;
    }
  }

  .next {
    right: 10px;
  }

  .prev {
    left: 10px;
    transform: scale(-1);
  }
  .lightboxnav {
    color: ${theme.colors.orange} !important;
  }
  .tl-wrapper {
    width: 100% !important;
    overflow: hidden !important;
  }
  .tl-edges {
    overflow-x: visible !important;
  }
  .gatsby-plugin-transition-link-portal {
    width: 100%;
  }
  .gatsby-resp-iframe-wrapper {
    width: 100%;
    position: fixed;
    .embedVideo-container {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .alltags {
    font-family: "Montserrat";
    font-size: ${rhythm(1 / 1.9)};
    color: ${theme.brand.primaryfade3};
    margin-bottom: ${rhythm(1)};
    &:hover {
      color: ${theme.brand.primaryfade};
    }
  }
  .iconwrap {
    grid-area: description;
  }
  .blog-image {
    grid-area: image;
  }
  .blogpost {
    p {
      margin-bottom: ${rhythm(1)};
      position: relative;
    }
    h2 {
      margin: 0 auto;
      text-align: center;
      color: ${theme.brand.primary};
      margin-bottom: ${rhythm(1)};
    }
    a {
      background-image: ${props => props.theme.brand.orangered};
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-decoration: underline;
      position: relative;
      white-space: nowrap;
      :before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        display: inline-block;
        height: 1px;
        opacity: 0.25;
        background: ${theme.brand.primaryalt};
      }
      :hover {
        :after {
          opacity: 1;
        }
      }
    }
  }
  .blogbutton {
    display: inline-flex;
    padding: 0.5rem 1.33rem;
    margin: 0.5rem 1rem;
    position: relative;
    background-clip: text;
    border: 1px solid transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    background-image: ${props => props.theme.brand.prptealrev};
    transition: ${props => props.theme.transitions.circ};
    z-index: 100;
    span {
      text-transform: uppercase;
      font-style: normal;
      margin: 0 auto;
    }

    &:after {
      content: "";
      height: 0%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -5;
      transition: ${props => props.theme.transitions.circ};
    }

    &:hover {
      background-image: ${props => props.theme.brand.prptealrev};
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      background-position-y: 0;
      background-clip: text;
      -webkit-text-fill-color: ${props => props.theme.brand.primary};
      -webkit-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 14px 23px -14px rgba(0, 0, 0, 0.75);
      transition: ${props => props.theme.transitions.circ};
      :after {
        transition: ${props => props.theme.transitions.circ};
        height: 100%;
      }
    }
  }
  .blogloader {
    color: ${theme.brand.primaryalt};
    opacity: 0.66;
    transition: all 0.5s ease-in-out;
    :hover {
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }
  }
  .blog-list-item {
    max-width: ${theme.spacing.innerwrap};
  }
  .post-tags {
    font-family: "Teko";
    margin-right: ${rhythm(1 / 4)};
    font-size: ${rhythm(1 / 2)};
    text-transform: uppercase;
    color: ${theme.brand.primaryalt};
  }
  .taglist {
    li {
      margin: 0;
      padding: ${rhythm(0.5)} 0 ${rhythm(0.33)} ${rhythm(1.5)};
      list-style: none;
      background-image: url(${favicon});
      background-repeat: no-repeat;
      background-position: left center;
      background-size: ${rhythm(0.75)};
      a {
        font-family: "Teko";
        font-size: ${rhythm(1)};
        text-transform: uppercase;
        color: ${theme.brand.primaryalt};
      }
    }
  }
  .post-date {
    font-size: ${rhythm(0.44)};
    grid-area: "keepreading";
  }
  .postsublink,
  .postprelink {
    color: ${theme.brand.primaryalt};
  }
  .postsublink {
    font-size: ${rhythm(0.5)};
    text-transform: uppercase;
  }
  .postprelink {
    font-size: ${rhythm(0.66)};
    text-transform: uppercase;
  }
  .responsiveutilities {
    background-color: rgba(255, 255, 255, 0.25);
    width: 15vw;
    margin: ${rhythm(4)} 0;
    .yscroll {
      color: red;
    }
  }
  .tilebg {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23273848' fill-opacity='0.05'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  .workshopactive {
    filter: grayscale(0%) !important;
    opacity: 1 !important;
    transition: all 0.5s ease-in-out;
  }
  #lightbox {
    .ddLightboxArrows {
      cursor: pointer;
    }
    .gatsby-image-wrapper {
      -webkit-user-select: none; /* Chrome/Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+ */
      /* Rules below not implemented in browsers yet */
      -o-user-select: none;
      user-select: none;
    }
  }
  .contactSide {
    opacity: 0.665;
    padding: ${rhythm(0.25)};
    &:hover {
      opacity: 1;
    }
  }
  .ddLightboxArrows {
    opacity: 0.66;
    transition: all 0.5s ease-in-out;
    :hover {
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }
  }
  .project_subtext {
    z-index: 100;
    color: white;
    max-width: 50%;
    margin: 0 auto;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
      text-align: left;
      margin: auto 0;
      padding-bottom: 0.5em;
      color: ${props => props.theme.brand.primaryalt};
    }
    h6 {
      color: ${props => props.theme.brand.primaryfade3};
      padding-top: 0.5em;
      text-align: right;
      padding-right: 0.25rem;
      border-top: 1px dotted ${props => props.theme.brand.primaryfade2};
    }
  }
  .activenavhoverdiv {
    opacity: 1 !important;
    position: relative;
    &:after {
      content: "";
      height: ${rhythm(1 / 12)};
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      z-index: -5;
      background: ${props => props.theme.brand.orangered};
      transition: ${props => props.theme.transitions.circ};
    }
  }
  .pagemargintop {
    margin-top: ${theme.spacing.headerheight} !important;
  }
  .pagepadtop {
    padding-top: ${theme.spacing.headerheight} !important;
  }
  .pagemarginbtm {
    padding-bottom: ${rhythm(3.5)};
  }
  .padbtm {
    padding-bottom: ${theme.spacing.sectionpad};
  }
  .padtop {
    padding-top: ${theme.spacing.sectionpad};
  }
  .padtopsm {
    padding-top: ${theme.spacing.sectionpad} / 2;
  }
  .whitetext {
    color: white;
  }
  .p50 {
    max-width: 60ch;
  }
  .p33 {
    max-width: 33ch;
  }
  .dd50left {
    max-width: 50%;
    margin-left: 0;
    margin-right: auto;
  }
  .dd50right {
    max-width: 50%;
    margin-left: auto;
    margin-right: 0;
  }
  .smallmarginbtm {
    padding-bottom: ${rhythm(1.5)};
  }
  .pagemargintopsm {
    padding-top: ${rhythm(1.5)};
  }
  .innerwrap {
    max-width: ${theme.spacing.innerwrap} !important;
    margin: 0 auto;
  }
  .innerwrapmed {
    max-width: ${theme.spacing.innerwrapmed};
    margin: 0 auto;
  }
  .innerwrapsmall {
    max-width: ${theme.spacing.innerwrapsmall};
    margin: 0 auto;
  }
  .animSlowYIn {
  }
  .nooverflow {
    overflow: hidden;
  }
  .insetshadow {
    -webkit-box-shadow: inset 0 8px 6px -6px black;
    -moz-box-shadow: inset 0 8px 6px -6px black;
    box-shadow: inset 0 8px 6px -6px black;
  }
  .gradtextprp {
    background-image: ${props => props.theme.brand.prptealrev};
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradtextred {
    background-image: ${props => props.theme.brand.orangered};
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradtextgrn {
    background-image: ${props => props.theme.brand.blgrn};
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .outlinedtxt1 {
    color: ${props => props.theme.colors.primarydark};
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${props => props.theme.colors.primarydark};
  }
  .outlinedtxt2 {
    line-height: 167.5px;
    letter-spacing: -15px;
    color: ${props => props.theme.colors.primarydark};
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }
  .outlinedtxt3 {
    color: ${props => props.theme.colors.primarydark};
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }
  .smallshadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .medshadow {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15), 0 5px 5px rgba(0, 0, 0, 0.11);
  }
  .largeshadow {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  .insetshadow {
    -webkit-box-shadow: inset 0 8px 6px -6px black;
    -moz-box-shadow: inset 0 8px 6px -6px black;
    box-shadow: inset 0 8px 6px -6px black;
  }
  .active {
    span:after {
      content: "";
      display: inline-block;
      margin: 0 auto;
      transition: all 0.5s ease-in-out;
      width: 100%;
      left: 0%;
      height: 2px;
      bottom: 2px;
      background: ${props => props.theme.brand.orangered};
      position: absolute;
      z-index: 100;
      transition: all 0.5s cubic-bezier(0.89, 0.29, 0.16, 0.93);
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
      opacity: 1;
    }
  }
  .active2 {
    &:after {
      content: "";
      display: inline-flex;
      margin: 0 auto;
      transition: all 0.5s ease-in-out;
      width: 100%;
      height: 2px;
      bottom: -8px;
      background: ${props => props.theme.brand.orangered};
      position: absolute;
      z-index: 100;
      transition: all 0.5s cubic-bezier(0.89, 0.29, 0.16, 0.93);
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
      font-weight: 700;
      opacity: 1;
    }
  }
  .activeTakeover {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
      font-weight: 700;
      opacity: 1 !important;
      &:after {
        content: "";
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        left: 0%;
        height: 2px;
        bottom: 0px;
        background: ${props => props.theme.brand.prpteal};
        position: absolute;
        z-index: 100;
        transition: all 0.5s cubic-bezier(0.89, 0.29, 0.16, 0.93);
      }
    }
    svg * {
      fill: white;
      stroke: white;
      opacity: 0.75;
    }
  }
  .activeSVG {
    opacity: 1 !important;
    svg {
      opacity: 1 !important;
      * {
        opacity: inherit;
      }
    }
  }
`

export default reset
