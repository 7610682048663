import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import TagWrapper from "./TagWrapper"
import { below } from "../../../config/utilities"
import { PhaseHeader } from "../../elements"
import { rhythm } from "../../../config/typography"
import Stats from "./Stats"
import DDVideo from "./DDVideo"
const StartContainer = ({
  startParagraphA,
  startParagraphB,
  tags,
  count,
  name,
  isStats,
  frontmatter,
  vidA,
  vidB,
  vidHalf,
  imageHalf,
  softwareTags,
}) => {
  const sideImage = (
    <SideImageB>
      {imageHalf && <Img fluid={imageHalf} />}
      {vidA && <DDVideo vidSrc={vidA} vidTitle={vidA} />}
      {vidB && <DDVideo vidSrc={vidB} vidTitle={vidB} />}
    </SideImageB>
  )
  const sideVideo = (
    <ProjectStoryWrap secondary className="innerwrap" halfImage>
      <Inner>
        <PhaseHeader count={count} name={name} secondary />
        <div className="paraanim">
          <p>{startParagraphA}</p>
          <p>{startParagraphB}</p>
        </div>
        <TagWrapper tags={tags} softwareTags={softwareTags} />
      </Inner>
      <div
        css={`
          overflow: hidden;
          width: 100%;
          height: 100%;
          position: relative;
          video {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        `}
      >
        <DDVideo vidSrc={vidHalf} vidTitle={vidHalf} />
      </div>
    </ProjectStoryWrap>
  )
  let startContainer
  if (isStats) {
    startContainer = (
      <React.Fragment>
        {isStats && (
          <StatsWrap>
            <Stats metrics={frontmatter.metrics} />
          </StatsWrap>
        )}
        <Container key={name}>
          <ProjectStoryWrap secondary className="innerwrap">
            <Inner>
              <PhaseHeader count={count} name={name} secondary />
              <div className="paraanim">
                <p>{startParagraphA}</p>
                <p>{startParagraphB}</p>
              </div>
              <TagWrapper tags={tags} softwareTags={softwareTags} />
            </Inner>
          </ProjectStoryWrap>
          {sideImage}
          <ColorWrap />
        </Container>
      </React.Fragment>
    )
  } else if (vidHalf) {
    startContainer = (
      <React.Fragment>
        <Container key={name}>
          {sideVideo}
          {sideImage}
          <ColorWrap />
        </Container>
      </React.Fragment>
    )
  } else if (!isStats) {
    startContainer = (
      <React.Fragment>
        <Container key={name}>
          <ProjectStoryWrap className="innerwrap">
            <Inner>
              <PhaseHeader count={count} name={name} secondary />
              <div className="paraanim">
                <p>{startParagraphA}</p>
                <p>{startParagraphB}</p>
              </div>
              <TagWrapper tags={tags} softwareTags={softwareTags} />
            </Inner>
            {vidHalf && (
              <div>
                <DDVideo vidSrc={vidHalf} vidTitle={vidHalf} />
              </div>
            )}
          </ProjectStoryWrap>
          {sideImage}
          <ColorWrap />
        </Container>
      </React.Fragment>
    )
  }
  return <React.Fragment>{startContainer}</React.Fragment>
}

StartContainer.propTypes = {
  startParagraphA: PropTypes.string,
  startParagraphB: PropTypes.string,
  tag1: PropTypes.string,
  tag2: PropTypes.string,
  tag3: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default StartContainer

export const Container = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 5;
`
const ProjectStoryWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  text-align: center;
  padding: ${rhythm(4)};
  ${props =>
    props.secondary &&
    css`
      width: 100%;
      margin: 0 auto;
    `}
  ${props =>
    props.halfImage &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-row-gap: ${rhythm(1)};
      grid-column-gap: ${rhythm(1)};
      ${below.s`
        grid-template-columns:1fr;
        grid-template-rows:1fr;
      `}
    `}
    ${below.s`
    padding: ${rhythm(2)};
`}
    ${below.xs`
    padding: ${rhythm(2)} ${rhythm(1)};
`}
`
export const Inner = styled.div`
  display: grid;
  grid-row-gap: ${rhythm(1)};
  grid-template-areas:
    "header "
    "paragraph "
    "tags ";
  max-width: 55ch;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 0;
  margin: 0 auto;
  .animSlowYIn {
    place-self: center stretch;
  }
  p {
    color: white;
    grid-area: paragraph;
    text-align: left;
    margin: auto;
    &:first-of-type {
      margin-bottom: ${rhythm(1)};
    }
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
  video {
    display: flex;
    height: 100%;
    width: 50vw;
    padding-right: 0;
  }
`
export const SideImageB = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  img,
  video {
    margin: auto;
    width: 100%;
  }
`
const StatsWrap = styled.div`
  width: 100%;
  padding: ${rhythm(2)} ${rhythm(4)} ${rhythm(4)} ${rhythm(4)};
  background-color: white;
  min-width: 300px;
  ${below.xs`
      padding: ${rhythm(1)} ${rhythm(2)} ${rhythm(2)} ${rhythm(1)};
    `}
`
const ColorWrap = styled.div`
  background-color: ${props => props.theme.brand.blackfade};
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`
