/* eslint-disable quotes */
import { darken, complement, lighten, transparentize } from "polished"
import { rhythm } from "./typography"

const gradientswatches = {
  teal: "#03E1B7",
  midblue: "#427DD4 ",
  purple: "#6940E7",
}
const color = {
  primary: "#2c3e50",
}

const brand = {
  primary: "#2c3e50",
  primarylite: `${lighten(0.05, color.primary)}`,
  primarylitest: `${lighten(0.6, color.primary)}`,
  complement: `${complement(color.primary)}`,
  primaryalt: "rgb(34, 48, 62)",
  primaryfadext: "rgba(44,62,80,.99)",
  primaryfade: "rgba(44,62,80,.95)",
  primaryfade2: "rgba(44,62,80,.5)",
  primaryfade3: "rgba(44,62,80,.75)",
  blackfade: "rgba(13, 18, 24, 0.85)",
  secondary: "#feb36f",
  prpteal:
    " linear-gradient(  -135deg,  #03E1B7 0%,    #427DD4 50%,   #6940E7 100%)",
  prptealrev:
    " linear-gradient(  135deg,  #03E1B7 0%,    #427DD4 50%,   #6940E7 100%)",
  blgrn: " linear-gradient(  -135deg,  #A8FE79 0%, #78FFD6 100%)",
  orangered:
    "linear-gradient( 200deg,  #ffb66f 0%,  #f5946b 20%,  #eb7367 40%,  #e15164 60%,  #d73060 80%,  #cd0e5c 100%)",
  orangepeach: "linear-gradient( 90deg, #00CAC7 0%, #02e4b7 50%, #00ACFF 100%)",
}

const spacing = {
  innerwrap: "1200px",
  innerwrapmed: "900px",
  innerwrapsmall: "720px",
  scrollbarwidth: `${rhythm(0.33)}`,
  headerheight: `96px`,
  sectionpad: `${rhythm(1.5)}`,
}
const colors = {
  litestgrey: "#fdfdfd",
  greytransp: `${transparentize(0.05, "#fdfdfd")}`,
  grey: "#595959",
  // #838383
  midgrey: `${lighten(0.33, "#6b6b6b")}`,
  darkgrey: `${darken(0.2, "#838383")}`,
  litegrey: "#F3F3F2",
  black: "#000",
  lime: `${lighten(0.13, "#78FFD6")}`,
  blue: `${darken(0.1, brand.primary)}`,
  orange: "#feb36f",
  teal: "#03E1B7",
  purple: "#6940E7",
  purplelite: `${lighten(0.33, "#6940E7")}`,
  white: "#fff",
  primarylite: `${lighten(0.15, brand.primary)}`,
  primarylite2: `${lighten(0.35, brand.primary)}`,
  complementlite: `${lighten(0.35, brand.complement)}`,
  link_color: `${lighten(0.25, brand.primary)}`,
  link_color_hover: `${darken(0.15, brand.primary)}`,
  primarydark: `${darken(0.15, brand.primary)}`,
  primarydarkest: `${darken(0.2, brand.primary)}`,
  primarydark2: `${darken(0.05, brand.primary)}`,
  prpteal: brand.prpteal,
  orangered: brand.orangered,
  bg_color: "#f3f3f3",
  body_color: "#444",
}

const fades = {
  primaryaltfade: `${transparentize(0.05, colors.primarydark2)}`,

  contactmodal: `${transparentize(0.1, colors.link_color_hover)}`,
  header_divider: `${transparentize(0.05, colors.litestgrey)}`,
}
const transitions = {
  circ: "all 0.75s cubic-bezier(0.15, 0.65, 0.4, 1)",
  circfast: "all 0.33s cubic-bezier(0.15, 0.65, 0.4, 1)",
  circsec: "0.75s cubic-bezier(0.15, 0.65, 0.4, 1)",
  ease: "all 0.75s ease-in-out",
  plExitDelay: 0,
  plExitLength: 0,
  plEntryDelay: 0,
  plEntryDelay: 0,
}
const shadows = {
  btmright:
    "2px 2px 0px 0px #f2f3f4, 4px 4px 0px 0px #2c3e50,  6px 6px 0px 0px #f2f3f4, 8px 8px 0px 0px #2c3e50, 3px 3px 0px 0px #f2f3f4,  4px 4px 0px 0px #2c3e50",
  leftbtmsmall:
    "-2px 2px 0px 0px #2c3e50, -4px 4px 0px 0px white, -6px 6px 0px 0px #2c3e50, -8px 8px 0px 0px white",
  materialsmall: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
}

const theme = {
  color,
  gradientswatches,
  transitions,
  brand,
  colors,
  shadows,
  fades,
  spacing,
  breakpoints: {
    xs: "415px",
    s: "768px",
    m: "1024px",
    l: "1400px",
    xl: "2000px",
  },
  container: {
    base: "100rem",
    text: "55rem",
  },
  spacer: {
    horizontal: "2rem",
    vertical: "3rem",
  },
}

export default theme
