import React from "react"
import styled from "styled-components"
import { below } from "../../../config/utilities"
import { rhythm } from "../../../config/typography"

const TagWrapper = ({ tags }) => {
  const tagMap = tags.map((tag, index) => (
    <div key={tag} delay={(index + 1) * 100}>
      <h6 className="tags">{tag}</h6>
    </div>
  ))

  return <GridItem gridArea="tags">{tagMap}</GridItem>
}

export default TagWrapper

const GridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  ${below.s`
    justify-content:center;
    `}

  .tags {
    background-color: white;
    background-size: cover;
    font-weight: 400;
    font-size: ${rhythm(1 / 2)};
    color: white;
    color: ${props => props.theme.brand.primary};
    margin: 0;
    text-align: center;

    padding: ${rhythm(1 / 3)} ${rhythm(1 / 2)};
    &:first-of-type,
    &:nth-of-type(2) {
      margin-right: ${rhythm(1 / 2)};
    }
  }
`
