import React, { useState, useEffect } from "react"
import vertLogo from "./vertlogo.json"
import LottieInView from "./LottieInView"

const LogoAnim = ({ speed, isNavOpen, width }) => {
  const [reverse, setReverse] = useState(1)
  useEffect(() => {
    if (isNavOpen) {
      setReverse(1)
    } else if (!isNavOpen) {
      setReverse(-1)
    }
  }, [isNavOpen, reverse])
  return (
    <React.Fragment>
      <LottieInView
        animationData={vertLogo}
        speed={speed}
        width={width || `100%`}
        height="100%"
        threshold={1}
        inViewTimeout={100}
      />
    </React.Fragment>
  )
}

export default LogoAnim
