/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import { rhythm } from "../../../config/typography"
import { useLockBodyScroll } from "react-use"
import { FaTimes } from "react-icons/fa"
import { NavContext } from "../../context/NavContext"
import { motion, AnimatePresence } from "framer-motion"

const modalVariants = {
  open: {
    opacity: 1,
    height: 100 + `%`,
    transition: {
      damping: 50,
      stiffness: 300,
      delayChildren: 1,
    },
  },
  closed: {
    opacity: 0,
    height: 0 + `%`,
    transition: {
      damping: 30,
      stiffness: 200,
      delay: 0.35,
      when: `afterChildren`,
    },
  },
}
const modalChildVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.25,
    },
  },
  closed: {
    opacity: 0,
    y: -100,
    transition: {
      delay: 0,
      duration: 0.35,
    },
  },
}
const Modal = ({
  children,
  on,
  animKey,
  closeModal,
  modalPadding,
  useCloseButton,
  bgColor,
  useModalCard,
}) => {
  const { isModalOpen, setModalOpen } = useContext(NavContext)

  let modalChildren
  if (useModalCard === true) {
    modalChildren = (
      <ModalCard
        variants={modalChildVariants}
        initial="closed"
        animate="open"
        exit="closed"
        padding={modalPadding}
      >
        {children}
      </ModalCard>
    )
  } else if (useModalCard === false) {
    modalChildren = <>{children}</>
  }
  useEffect(() => {
    if (on) {
      setModalOpen(true)
    } else {
      setModalOpen(false)
    }
  }, [on])
  useLockBodyScroll(isModalOpen)
  return (
    <AnimatePresence>
      {isModalOpen && (
        <ModalContainer
          variants={modalVariants}
          initial="closed"
          animate="open"
          exit="closed"
          key={animKey}
        >
          {modalChildren}
          {useCloseButton && <CloseModalBtn closeModal={closeModal} />}
          <Background onClick={closeModal} bgColor={bgColor} />
        </ModalContainer>
      )}
    </AnimatePresence>
  )
}

export const CloseModalBtn = ({ closeModal, children }) => (
  <>
    <XButton onClick={closeModal}>
      <FaTimes color="#fff" className="faClose" fixedWidth />
    </XButton>
    {children}
  </>
)

const XButton = styled.div`
  position: absolute;
  display: inline-flex;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  background: transparent;
  margin: ${rhythm(1)};
  z-index: 10000000000;
  cursor: pointer;
  transform-origin: center center;
  transition: ${props => props.theme.transitions.circ};
  transition-duration: 400ms;
  opacity: 0.8;
  padding: ${rhythm(1 / 3)};
  &:hover {
    opacity: 1;
    transform: rotate(180deg) scale(1);
    transition: ${props => props.theme.transitions.circ};
    transition-duration: 400ms;
  }
`

const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150000000;
`
const ModalCard = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding || 0};
  z-index: 160000000;
  position: relative;
`
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 140000000;
  background-color: ${props => props.bgColor};
`
export default Modal
