import React from "react"
import styled from "styled-components"
import { rhythm } from "../../../config/typography"
import { motion } from "framer-motion"

const menuVariants = {
  closed: {
    rotate: 0,
    opacity: 1,
    x: 0,
  },
  open: { rotate: 45 },
  transition: {
    damping: 160,
  },
  open2: { x: -45, opacity: 0 },
  transition: {
    damping: 160,
  },
  open3: { rotate: -45 },
  transition: {
    damping: 160,
  },
}

const Menu = props => {
  const { navigationColor, isNavOpen, setNavSVG } = props
  return (
    <StyledBurger
      navigationColor={navigationColor}
      open={isNavOpen}
      setNavSVG={setNavSVG}
      aria-label="Open Navigation"
      {...props}
    >
      <motion.div
        variants={menuVariants}
        initial="closed"
        animate={isNavOpen ? `open` : `closed`}
        className="smallshadow"
      />
      <motion.div
        variants={menuVariants}
        initial="closed"
        animate={isNavOpen ? `open2` : `closed`}
        className="smallshadow"
      />
      <motion.div
        variants={menuVariants}
        initial="closed"
        animate={isNavOpen ? `open3` : `closed`}
        className="smallshadow"
      />
    </StyledBurger>
  )
}

export default Menu

const StyledBurger = styled(motion.button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: ${rhythm(1)};
  height: ${rhythm(1)};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  &:focus {
    outline: none;
  }

  div {
    width: ${rhythm(1)};
    height: ${rhythm(1 / 8)};
    background: ${({ navigationColor }) => navigationColor};
    border-radius: 10px;
    transition: background 0.5s ease-in;
    position: relative;
    transform-origin: 1px;
  }
`
