/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { below } from "../../../config/utilities"
import { chunk } from "lodash"
import CoolLightbox from "./CoolLightbox"
import { useOrientation } from "react-use"
import { NavContext } from "../../context/NavContext"

const Lightbox = props => {
  const { isModalOpen, setModalOpen } = useContext(NavContext)

  const { images } = props
  const [showLightbox, setShowLightbox] = useState(false)
  const [activeImage, setActiveImage] = useState(0)
  const itemsPerRow = 3
  const state = useOrientation()

  const handleClick = (e, index) => {
    e.preventDefault()

    setModalOpen(true)

    setActiveImage(index)
  }

  const handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (activeImage > 0) {
          setActiveImage(activeImage - 1)
        } else {
          setActiveImage(0)
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (activeImage < images.length - 1) {
          setActiveImage(activeImage + 1)
        } else {
          setActiveImage(0)
        }
      }
      if (keyCode === 27) {
        // Escape key
        setShowLightbox(false)
        window.scrollTo(0, document.body.scrollHeight)
      }
    }
  }
  const closeModal = () => {
    setShowLightbox(false)
    setModalOpen(false)
  }

  const goBack = () => {
    if (activeImage > 0) {
      setActiveImage(activeImage - 1)
    } else {
      setActiveImage(0)
    }
  }

  const goForward = () => {
    if (activeImage < images.length - 1) {
      setActiveImage(activeImage + 1)
    } else {
      setActiveImage(0)
    }
  }
  useEffect(() => {
    window.addEventListener(`keyup`, handleKeyUp, false)
    return () => {
      window.removeEventListener(`keyup`, handleKeyUp, false)
    }
  }, [images[activeImage]])

  let modalImagePadding

  useEffect(() => {
    if (state === `landscape-primary`) {
      modalImagePadding = 0
    } else {
      modalImagePadding = `64px`
    }
  })

  useEffect(() => {
    if (showLightbox === true) {
      setModalOpen(true)
    } else if (showLightbox === false) {
      setModalOpen(false)
    }
  }, [showLightbox])
  return (
    <>
      <LightContainer
        css={`
          margin: 0 auto;
        `}
      >
        <Gallery
          images={images}
          itemsPerRow={itemsPerRow}
          handleClick={handleClick}
        />
      </LightContainer>
      <CoolLightbox
        images={images}
        currentImageIndex={activeImage}
        setCurrentIndex={setActiveImage}
        isOpen={showLightbox}
        onClose={closeModal}
        goBack={goBack}
        goForward={goForward}
        modalImagePadding={modalImagePadding}
      />
    </>
  )
}

export default Lightbox

const StyledImg = styled(Img)`
  min-height: 105%;
  filter: brightness(50%);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  &:hover,
  :active {
    filter: brightness(100%);
    transition: all 0.5s ease-in-out;
  }
`

const GalleryDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  height: auto;
  ${below.s`
        grid-template-columns: 1fr 1fr 1fr;`}
  ${below.xs`
        grid-template-columns: 1fr 1fr;`}
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`

const Gallery = ({ images, itemsPerRow, handleClick }) => {
  const rows = chunk(images, itemsPerRow)

  return (
    <GalleryDiv>
      {rows.map(row =>
        row.map((image, index) => (
          <GalleryItem key={image.src}>
            <a
              alt="Works Image"
              aria-label="Toggle Lightbox"
              onClick={e => handleClick(e, index)}
            >
              <StyledImg fluid={image} />
            </a>
          </GalleryItem>
        ))
      )}
    </GalleryDiv>
  )
}

const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  flex: calc(100% * 1 / 3);
  ${below.s`
    flex: calc(100% * 1 / 2 );
  `}
  ${below.xs`
    flex: calc(100% * 1  );
  `}
`

const LightContainer = styled.div`
  margin: auto;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${props => props.theme.brand.primaryalt};
  }
  h2 {
    margin-bottom: 0;
  }
`

Lightbox.propTypes = {
  images: PropTypes.array.isRequired,
}
