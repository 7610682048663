import React from "react"
import AnimationLink from "./AnimationLink"
import styled from "styled-components"
import uniIcon from "../../images/Icon2.png"

const WorkshopLink = ({ onClick }) => (
  <>
    <WorkshopAnimLink
      to="/blog"
      ariaLabel="Workshop Blog"
      enterState={{ fromTopNav: true }}
      exitState={{ fromTopNav: false }}
      state={{ fromTopNav: true }}
      partiallyActive
      onClick={onClick}
      activeClassName="workshopactive"
    >
      <WSImage src={uniIcon} alt="Workshop" />
    </WorkshopAnimLink>
  </>
)

const WorkshopAnimLink = styled(AnimationLink)`
  opacity: 0.25;
  transition: all 0.5s ease-in-out;
  filter: grayscale(100%);
  margin: 0 auto;
  :hover {
    opacity: 1;
    filter: grayscale(0%);
    transition: all 0.5s ease-in-out;
  }
`

const WSImage = styled.img`
  margin: 0 auto;
  width: 32px;
  height: 32px;
`
export default WorkshopLink
