import React from "react"
import { Tweet } from "react-twitter-widgets"
import { rhythm } from "../../config/typography"

export const LatestTweets = () => (
  <div
    css={`
      display: flex;
      flex-direction: column;
      align-items: normal;
      iframe {
        margin: ${rhythm(1)} auto;
      }
    `}
  >
    <Tweet
      tweetId="1127676236388556801"
      options={{
        username: `PAKDMedia`,
        height: `400`,
      }}
    />
  </div>
)

export default LatestTweets
