import Typography from "typography"
import { below } from "./utilities"
export const globalh6 = { name: "Teko", display: "display" }

const typography = new Typography({
  baseFontSize: "16px",
  includeNormalize: true,
  baseLineHeight: 1.666,
  scaleRatio: 2.88,
  googleFonts: [
    {
      name: "Montserrat",
      styles: ["400", "600", "900"],
    },
    {
      name: "Raleway",
      styles: ["300", "600", "900"],
    },
    {
      name: `${globalh6.name}`,
      styles: ["500"],
    },
  ],
  headerFontFamily: ["Montserrat", "Helvetica", "Arial", "sans-serif"],
  bodyFontFamily: ["Raleway", "Helvetica", "Arial", "sans-serif"],

  bodyGray: 25,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    "h1,h2,h3,h4,h5,h6": {
      lineHeight: 1.15,
    },
    h6: {
      fontSize: `${rhythm(0.66)}`,
    },
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== "production") {
  typography.injectStyles()
}

export const { scale, rhythm, options } = typography
export default typography
