import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"

const NavLogo = ({ navLogoColor, setNavSVG, isSetNavSVG }) => {
  const [toggle, setToggle] = useState(false)
  useEffect(() => {
    if (navLogoColor === `#ffffff`) {
      setToggle(true)
    }
    if (navLogoColor === `#2c3e50`) {
      setToggle(false)
    }
  }, [navLogoColor])

  return (
    <div>
      <svg viewBox="0 0 38.9 72">
        <motion.polygon
          initial={{ opacity: 0 }}
          animate={{
            opacity: toggle ? 0 : 1,
            transition: { duration: 0.5 },
          }}
          style={{ zIndex: -1, fill: `rgba(255,255,255,1)` }}
          points="0.2,60.7 12.5,60.7 12.5,71.5 25.8,71.5 38.6,60.2 38.6,0.4 13.1,0.4 0.2,11.7 "
        />
        <motion.path
          style={{ zIndex: 1 }}
          initial={{ fill: navLogoColor }}
          animate={{ fill: navLogoColor, transition: { duration: 0.5 } }}
          d="M0.2,60.7h12.3v10.8h13.3l12.8-11.3V0.4H13.1L0.2,11.7V60.7z M13.7,69.9v-4l11.5-10v4L13.7,69.9z M22.4,51.4
                H11.6l1.9-1.6h10.8L22.4,51.4z M19.3,54.2H8.5l1.9-1.7h10.8L19.3,54.2z M16.1,56.9H5.3l1.9-1.6H18L16.1,56.9z M1.3,59v-3.4l11.4-10
                V49L1.3,59z M37.6,59.5H26.2v-58h11.3V59.5z M25.2,48.7H13.8V1.5h11.3L25.2,48.7z M1.3,12.2l11.4-10v3.4l-11.4,10L1.3,12.2z M1.3,17
                L12.7,7v3.4l-11.4,10L1.3,17z M1.3,21.8l11.4-10v3.4l-11.4,10V21.8z M1.3,26.7l11.4-10V20L1.3,30V26.7z M1.3,31.5l11.4-10v3.4
                l-11.4,10L1.3,31.5z M1.3,36.3l11.4-10v3.4l-11.4,10L1.3,36.3z M1.3,41.2l11.4-10v3.4l-11.4,10L1.3,41.2z M1.3,46l11.4-10v3.4
                l-11.4,10L1.3,46z M1.3,50.8l11.4-10v3.4l-11.4,10L1.3,50.8z M4.1,58h10.8L13,59.7H2.3L4.1,58z M13.7,60.5l11.4-10v4l-11.4,10
                L13.7,60.5z M25.4,70.5H14.7l1.9-1.6h10.8L25.4,70.5z M28.6,67.8H17.8l1.9-1.7h10.8L28.6,67.8z M31.7,65H20.9l1.9-1.6h10.7L31.7,65z
                 M34.8,62.3H24.1l1.9-1.6h10.7L34.8,62.3z M18.6,10h1.3c0.6,0,1.1-0.2,1.5-0.6c0.2-0.2,0.4-0.4,0.5-0.7c0.2-0.5,0.2-1.1,0-1.7
                c-0.1-0.3-0.3-0.5-0.5-0.7C21.3,6.1,21,6,20.8,5.9c-0.3-0.1-0.6-0.2-0.8-0.2h-2.7v6.4h1.4L18.6,10z M18.6,7h1.2
                c0.2,0,0.5,0.1,0.6,0.2c0.3,0.3,0.3,0.9,0,1.2c0,0,0,0,0,0l0,0c-0.2,0.2-0.4,0.3-0.6,0.2h-1.1L18.6,7z M18.2,21.6h2.6l0.5,1.3h1.4
                l-2.5-6.4h-1.4l-2.5,6.3h1.4L18.2,21.6z M19.5,18.4l0.8,2h-1.6L19.5,18.4z M22.3,27.4l-2.3,3l2.3,3.5h-1.6l-1.5-2.4l-0.8,1v1.4H17
                v-6.5h1.4v3l2.3-3L22.3,27.4z M21.3,39.1c-0.6-0.6-1.5-1-2.3-1h-1.9v6.4H19c0.9,0,1.7-0.3,2.3-1c0.3-0.3,0.5-0.6,0.7-1
                c0.3-0.8,0.3-1.7,0-2.5C21.8,39.7,21.6,39.4,21.3,39.1L21.3,39.1z M20.7,42.1c-0.1,0.2-0.2,0.4-0.4,0.6c-0.4,0.3-0.9,0.5-1.4,0.5
                h-0.5v-3.7H19c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4C20.9,40.6,21.1,41.4,20.7,42.1L20.7,42.1z M31.3,17.9V19h2v1.4h-2v1.2
                h2.6v1.4h-4v-6.4h4v1.4H31.3z M29.5,33.8h1.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.5-0.6,0.7-1
                c0.5-1.2,0.2-2.6-0.7-3.5c-0.3-0.3-0.7-0.5-1.1-0.7c-0.4-0.2-0.8-0.3-1.3-0.2h-1.9V33.8z M30.9,28.7h0.5c0.3,0,0.5,0,0.7,0.1
                c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.4,0.6
                c-0.4,0.3-0.9,0.5-1.4,0.5h-0.5L30.9,28.7z M31.3,38.2h1.4v6.4h-1.4L31.3,38.2z M31.3,49l-2.6,6.4h1.4l0.5-1.3h2.6l0.6,1.3h1.4
                L32.6,49L31.3,49z M31.1,52.8l0.8-2l0.8,2H31.1z M29.6,12.1h-1.4l1.6-6.4H31l1,2.6l1-2.6h1.2l1.6,6.4h-1.5l-0.9-3.7l-1,2.8h-0.8
                l-1-2.8L29.6,12.1z"
        />
      </svg>
    </div>
  )
}

export default NavLogo
