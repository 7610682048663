import React, { useState, useEffect } from "react"
import { useSpring, animated, config } from "react-spring"
import { useInView } from "react-intersection-observer"

export const AnimSlowYIn = ({ children, delay }) => {
  const [on, setOn] = useState(false)
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  const { o, xyz } = useSpring({
    from: { o: 0, xyz: [0, 100, 0] },
    o: on ? 1 : 0,
    xyz: on ? [0, 0, 0] : [0, 100, 0],
    delay,
  })
  useEffect(() => {
    if (inView) {
      const timeout = setTimeout(() => {
        // Update state, to trigger a rerender here
        setOn(true)
      }, delay || 1000)
      return () => clearTimeout(timeout)
    }
    return undefined
  }, [inView])
  return (
    <animated.div
      className="animSlowYIn"
      ref={ref}
      style={{
        opacity: o,
        transform: xyz.interpolate(
          (x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`
        ),
      }}
    >
      {children}
    </animated.div>
  )
}

AnimSlowYIn.defaultProps = {
  delay: `0`,
}

export const AnimSlowXIn = ({ children, delay }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  })
  const spring = useSpring({
    from: { opacity: 0, transform: `translate3d(100px,0,0)` },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? `translate3d(0,0,0)` : `translate3d(100px, 0, 0)`,
    },
    config: config.slow,
    delay,
  })
  return (
    <animated.div ref={ref} style={{ ...spring }}>
      {children}
    </animated.div>
  )
}

AnimSlowXIn.defaultProps = {
  delay: `0`,
}

export const FadeIn = ({ children, delay }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  })
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: inView ? 1 : 0 },

    delay,
  })
  return (
    <animated.div ref={ref} style={{ ...spring }}>
      {children}
    </animated.div>
  )
}
FadeIn.defaultProps = {
  delay: `0`,
}
export const FadeOut = ({ children, delay }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  })
  const spring = useSpring({
    from: { opacity: 1 },
    to: { opacity: inView ? 0 : 1 },
    delay,
  })
  return (
    <animated.div ref={ref} style={{ ...spring }}>
      {children}
    </animated.div>
  )
}

FadeOut.defaultProps = {
  delay: `0`,
}
