import { CaseStudyBanner, CaseStudyTriImage, CaseStudyFullWidthImage, StartContainer, Lightbox, Stats } from "../../../../src/components/worksdd";
import vidA from "../../../../src/posts/AVENTIRI/Adventure Book.mp4";
import * as React from 'react';
export default {
  CaseStudyBanner,
  CaseStudyTriImage,
  CaseStudyFullWidthImage,
  StartContainer,
  Lightbox,
  Stats,
  vidA,
  React
};