import styled, { css } from "styled-components"
import React from "react"
import { Row, Col } from "react-grid-system"
import { rhythm } from "../../config/typography"

import { below } from "../../config/utilities"
// Header1 is the title header for individual sections.

// eslint-disable-next-line react/prop-types

export const Header1 = ({
  secondary,
  lineOne,
  lineTwo,
  contact,
  subitem,
  margintop,
  marginbottom,
}) => {
  let header1
  if (secondary) {
    header1 = (
      <HeaderWrap1 margintop={margintop} marginbottom={marginbottom} secondary>
        <h1>{lineOne}</h1>
        <h6>{lineTwo}</h6>
        <div />
      </HeaderWrap1>
    )
  } else if (contact) {
    header1 = (
      <HeaderWrap1 margintop={margintop} marginbottom={marginbottom} contact>
        <h1 className="gradtextprp">{lineOne}</h1>
        <h6>{lineTwo}</h6>
      </HeaderWrap1>
    )
  } else if (subitem) {
    header1 = (
      <HeaderWrap1 margintop={margintop} marginbottom={marginbottom} contact>
        <h1 className="gradtextprp">{lineOne}</h1>
        <h6>{lineTwo}</h6>
      </HeaderWrap1>
    )
  } else {
    header1 = (
      <HeaderWrap1 margintop={margintop} marginbottom={marginbottom}>
        <h1>{lineOne}</h1>
        <h6>{lineTwo}</h6>
        <div />
      </HeaderWrap1>
    )
  }

  return (
    <Row justify="center" align="center">
      <Col xs="content">{header1}</Col>
    </Row>
  )
}

const HeaderWrap1 = styled.div`
    h1 {
      margin-bottom: ${rhythm(0.1)};
      color: ${props => props.theme.brand.primaryalt};
      text-align: center;
    }
    h6 {
      color: ${props => props.theme.colors.grey};
      text-align: center;
      margin-bottom:${props => props.marginbottom || `${rhythm(1.5)}`};
      &:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        margin: ${rhythm(0.1)} auto;
        background: ${props => props.theme.brand.prpteal};
      }
    }
    ${props =>
      props.margintop &&
      css`
        h1 {
          margin-top: 0 !important;
        }
      `}
      ${props =>
        props.marginbottom &&
        css`
          h6 {
            margin-bottom: 0 !important;
          }
        `}
    ${props =>
      props.secondary &&
      css`
        h1 {
          color: white;
        }
        & h6 {
          color: white;
        }
      `}
    ${props =>
      props.contact &&
      css`
        margin-top: 0;
        margin-bottom: 0;
        h1 {
          font-size: ${rhythm(2.77)};
          text-align: center;
          ${below.m`
            text-align:center;        
          `}
          ${below.xs`
            font-size: ${rhythm(1.5)};
          `}
        }
        h6 {
          display: none;
          text-align: left;
          &:after {
            display: none;
          }
        }
      `}
    ${props =>
      props.subitem &&
      css`
        h1 {
          font-size: 1.5rem;
          font-weight: lighter;
          color: ${props => props.theme.colors.midgrey} !important;
        }
        & h6 {
          display: none;
          color: ${props => props.theme.colors.midgrey} !important;
        }
        div {
          display: none;
        }
      `}
  `

// DDHeader is the header for the DD Banner.

export const DDHeader = ({ lineOne, lineTwo, children }) => (
  <DDHeaderWrap>
    <h1>{lineOne}</h1>
    <h6>{lineTwo}</h6>
    <div />
    {children}
  </DDHeaderWrap>
)
const DDHeaderWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  h1 {
    margin: 0.25rem;
    color: white;
  }
  h6 {
    margin: 0.25rem;
    color: ${props => props.theme.colors.litestgrey};
  }
  div:first-of-type {
    content: "";
    display: inline-block;
    width: 100%;
    height: 1px;
    margin-top: 0.5rem;
    background-image: ${props => props.theme.brand.orangered};
  }
  ${props =>
    props.secondary &&
    css`
      h1 {
        color: ${props => props.theme.brand.primary};
      }
      h6 {
        color: ${props => props.theme.colors.grey};
      }
    `}
`

export const PhaseHeader = ({
  secondary,
  tertiary,
  count,
  heroCount,
  name,
  children,
}) => {
  let phaseProps
  if (secondary) {
    phaseProps = <PhaseContainer secondary>{children}</PhaseContainer>
  } else if (tertiary) {
    phaseProps = <PhaseContainer tertiary>{children}</PhaseContainer>
  } else phaseProps = <PhaseContainer>{children}</PhaseContainer>

  return (
    <PhaseContainer {...phaseProps}>
      <h2 className="count">{count}</h2>
      <h2 className="name">{name}</h2>
      {tertiary ? <h6 className="outlinedtxt2 hero">{heroCount}</h6> : null}
      {children}
    </PhaseContainer>
  )
}

const PhaseContainer = styled.div`
    display: flex;
    margin: 0;
    width: max-content;
    position: relative;
    grid-area: header;
    align-items:start;
    h2 {
      z-index: 10;
      padding: 0.5em 0.5em;
    }
    .count {
      color: white;
      border: 1px solid white;
      margin-bottom:0;
    }
    .name {
      background-color: white;
      color: ${props => props.theme.brand.primaryalt};
      text-transform: uppercase;
      text-align: center;
      border: 1px solid white;
      margin-bottom:0;
    }
    ${below.s`
        margin:0 auto;
    `}

    ${props =>
      props.secondary &&
      css`
        grid-area: header;
        h2 {
          z-index: 10;
        }
        .count {
          color: white;
          border: 1px solid white;
        }
        .name {
          background-color: white;
          color: ${props => props.theme.brand.primaryalt};
          width: 100%;
          text-transform: uppercase;
          text-align: center;
          border: 1px solid white;
        }
        ${below.s`
        margin:0 auto;
    `}
      `}
    ${props =>
      props.tertiary &&
      css`
        border: 0px solid ${props => props.theme.brand.primaryalt};

        flex-direction: row;

        .count {
          color: ${props => props.theme.brand.primaryalt};
          background-color: ${props => props.theme.colors.litegrey};
          text-align: center;
          border: 1px solid ${props => props.theme.brand.primaryalt};
        }
        .name {
          background-color: ${props => props.theme.brand.primaryalt};
          color: white;
          text-align: center;
          border: 1px solid ${props => props.theme.brand.primaryalt};
        }
        .hero {
          display: none !important;
          visibility: collapse !important;
        }
      `}
  `

export const ContactSubhead = ({
  titleRow,
  contactIcon,
  contactTitle,
  contactInfo,
  contactItems,
}) => (
  <ContactSideItem
    contactItems={contactItems}
    titleRow={titleRow}
    contactIcon={contactIcon}
    contactTitle={contactTitle}
    contactInfo={contactInfo}
  />
)

const ContactRowStart = styled.li`
  margin: ${rhythm(0.5)} auto;
  display: flex;
  width: 100%;
  h5 {
    margin: 0 auto;
    white-space: pre-line;
    text-align: center;
    ${below.s`
    margin-left:${rhythm(0.25)};
    margin-right:auto;
    text-align:left`}
  }
`

const ContactSideItem = ({ titleRow, contactItems }) => (
  <Row align="center">
    <Col xs={12}>
      <SideHeader>{titleRow}</SideHeader>
      <Row>
        <ul
          css={`
            width: 100%;
          `}
          className="fa-ul"
        >
          {contactItems.map(item => (
            <ContactRowStart key={item.key}>{item.contactIcon}</ContactRowStart>
          ))}
        </ul>
      </Row>
    </Col>
  </Row>
)
export const ContactSideHeader = ({ titleRow }) => (
  <Row>
    <Col>
      <SideHeader>{titleRow}</SideHeader>
    </Col>
  </Row>
)

const SideHeader = styled.h6`
  margin: ${rhythm(0.5)} auto;
  text-align: left;
  position: relative;
  color: ${props => props.theme.brand.primary};
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    width: 100%;
    height: 1px;
    margin: 0 auto;
    background: ${props => props.theme.brand.orangered};
  }
`
