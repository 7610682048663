import { StartContainer, Lightbox, Stats } from "../../../../src/components/worksdd";
import videoTwo from "../../../../src/posts/COPADO/copadoVidA.mp4";
import videoOne from "../../../../src/posts/COPADO/TriVidHorizontal.mp4";
import * as React from 'react';
export default {
  StartContainer,
  Lightbox,
  Stats,
  videoTwo,
  videoOne,
  React
};