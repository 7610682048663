import React, { useState, useEffect } from "react"
import Lottie from "@dreamfliper/lottie-light-react-web"
import { useTimeout } from "react-use"
import { useInView } from "react-intersection-observer"

const LottieInView = ({
  animationData,
  id,
  speed,
  width,
  height,
  threshold,
  inViewTimeout,
  direction,
  loop,
}) => {
  const [on, toggle] = useState(true)
  const [ref, inView] = useInView({
    /* Optional options */

    threshold: threshold,
    triggerOnce: true,
  })

  const defaultOpts = {
    loop: loop || false,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: `xMidYMid meet`,
    },
  }
  useEffect(() => {
    if (inView) {
      const timeout = setTimeout(() => {
        // Update state, to trigger a rerender here
        toggle(!on)
      }, inViewTimeout || 0)
      return () => clearTimeout(timeout)
    }
    return undefined
  }, [inView])

  return (
    <div className="lottiewrapper">
      <Lottie
        options={defaultOpts}
        isPaused={on}
        title={id}
        speed={speed}
        ariaRole="img"
        width={width || `100%`}
        height={height || `100%`}
        direction={direction || 1}
      />
      <span ref={ref} />
    </div>
  )
}

export default LottieInView
