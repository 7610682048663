import { StartContainer, CaseStudyFullWidthImage, Lightbox, Stats } from "../../../../src/components/worksdd";
import videoThree from "../../../../src/posts/PRESYS/presysVidC.mp4";
import videoJoysticks from "../../../../src/posts/PRESYS/presys_joysticks.mp4";
import * as React from 'react';
export default {
  StartContainer,
  CaseStudyFullWidthImage,
  Lightbox,
  Stats,
  videoThree,
  videoJoysticks,
  React
};