import { graphql, useStaticQuery } from "gatsby"

export const useSiteQuery = () => {
  const data = useStaticQuery(
    graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            siteUrl
            description
            menuLinks {
              name
              link
              key
            }
          }
        }
      }
    `
  )
  return data
}
