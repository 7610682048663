import React, { useContext, useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import { useSpring, useSprings, animated } from "react-spring"
import { useInView } from "react-intersection-observer"
import { below } from "../../../config/utilities"
import { rhythm } from "../../../config/typography"
import { Row, Col, Container } from "react-grid-system"
import { NavContext } from "../../context/NavContext"
import { NavColorWaypoint } from "../../elements"

const StatRef = ({ metrics }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 1,
    triggerOnce: true,
    delay: 500,
  })
  const { yPos, setNavigationColor } = useContext(NavContext)
  const entranceRef = useRef()
  const containerRef = useRef()
  useEffect(() => {
    if (entranceRef.current) {
      const container = containerRef.current.getBoundingClientRect()
      const containerHeight = container.height
      const entrance = entranceRef.current.getBoundingClientRect()
      if (entrance.y < 0) {
        setNavigationColor("#2c3e50")
      }
      if (entrance.y <= containerHeight * -1) {
        setNavigationColor("#ffffff")
      } else if (entrance.y > 0) {
        setNavigationColor("#ffffff")
      }
    }
  }, [yPos])
  return (
    <Container fluid component={StatsWrap}>
      <div ref={containerRef}>
        <NavColorWaypoint ref={entranceRef} />
        <Row
          gutterWidth={64}
          align="center"
          justify="around"
          style={{
            padding: "32px",
            transform: "translate3d(0,-32px,0)",
            maxWidth: "600px",
            margin: "0 auto",
          }}
        >
          <StatMap metrics={metrics} inView={inView} />
        </Row>
        <span ref={ref} />
      </div>
    </Container>
  )
}
const StatMap = ({ metrics, inView }) => {
  const items = metrics
  const springnum = useSprings(
    2,
    items.map(item => ({
      from: {
        number: 0,
        opacity: 0,
        transform: "translate3d(0,50px,0)",
      },
      to: {
        number: inView ? item.num : 0,
        opacity: inView ? 1 : 0,
        transform: inView ? "translate3d(0,0,0)" : "translate3d(0,50px,0)",
      },
      delay: item.key * 100,
    }))
  )

  return springnum.map((props, index) => (
    <Col md="content" xs={12} key={items[index].key}>
      <Row align="center" justify="center" component={Pre}>
        <animated.span className="gradtextred">
          {springnum[index].number.interpolate(number => number.toFixed())}
        </animated.span>
      </Row>
      <Row align="center" justify="center" component={Mid}>
        {items[index].unit}
      </Row>
      <Row align="center" justify="center" component={Post}>
        {items[index].descr}
      </Row>
    </Col>
  ))
}
export default StatRef

const ColA = styled(Col)``
const ColAnim = animated(ColA)
const StatsWrap = styled.div`
  background-color: white;
  width: 100%;
  padding: 0 ${rhythm(2.5)};
  ${below.xs`
  padding: ${rhythm(1.33)};
  `}
`

const StatsWrapper = styled.div`
  
  display:flex;
  align-items:center;
  margin:0 auto;
  width:100%;
  position:relative;
 
   ${below.m`
  `}
  ${below.s`
  order:2;
  align-items:center;
  background-size: contain;
  flex-direction:column;
  `}
  ${below.xs`
  `}
`
const Stat = styled(animated.div)`
  grid-area: ${props => props.gridarea};
  text-align: center;
  padding: 0 ${rhythm(1)};
  margin: auto;
`
const Pre = styled(animated.div)`
  text-align: center;
  margin: auto !important;
  span {
    font-size: 5rem;
    font-weight: 900;
  }
`
const Mid = styled(animated.h2)`
  white-space: nowrap;
  text-align: center;
  margin-bottom: 0;
  font-style: normal;
  color: white;
  padding: 0.5em;
  font-size: 2rem;
  background: ${props => props.theme.colors.primarydark2};
  ${below.s`
      font-size:1.5em;
  `}
`
const Post = styled(animated.h6)`
  white-space: nowrap;
  text-align: center;
  margin-bottom: 0;
  font-style: normal;
  white-space: nowrap;
  color: white;
  padding: 0.5em;
  background-image: ${props => props.theme.brand.prpteal};
  font-size: 1.25rem;
  ${below.s`
  `}
`
