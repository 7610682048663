import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { NavContext } from "../../context/NavContext"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import Menu from "./Menu"
import AnimationLink from "./AnimationLink"
import { rhythm } from "../../../config/typography"
import { Row, Col, Container } from "react-grid-system"
import NavLogo from "../../svg/NavLogo"
import LogoAnim from "../lottie/vertlogo"
import { useLockBodyScroll } from "react-use"
import WorkshopLink from "./WorkshopLink"
import { withPrefix } from "gatsby"
import { below } from "../../../config/utilities"
const variants = {
  open: {
    y: 0,
    transition: {
      delay: 0.2,
      stiffness: 25,
      damping: 180,
    },
  },
  closed: {
    y: `-100%`,
    transition: {
      delay: 0.2,
      stiffness: 25,
      damping: 180,
    },
  },
}

const ulVariants = {
  open: {
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.4,
      staggerDirection: 1, // 1 forwards, -1 backwards
      when: `afterChildren`,
    },
  },
  closed: {},
}

const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    stiffness: 25,
    damping: 180,
  },
  closed: { y: -40, opacity: 0, stiffness: 25, damping: 180 },
  transition: {
    delay: 1,
  },
}

const Navigation = ({ data, location }) => {
  const links = data.site.siteMetadata.menuLinks
  const {
    isNavOpen,
    setNavOpen,
    navigationColor,
    setNavigationColor,
    setNavSVG,
    isSetNavSVG,
  } = useContext(NavContext)

  useEffect(() => {
    if (isNavOpen) {
      setNavigationColor(`#ffffff`)
    }
  }, [isNavOpen])
  const isHomepage = location.pathname === withPrefix(`/`)

  useEffect(() => {
    if (isHomepage) {
      setNavigationColor(`#ffffff`)
    } else if ([`story`, `blog`, `/works/`].includes(location.pathname)) {
      setNavigationColor(`#ffffff`)
    } else {
      setNavigationColor(`#2c3e50`)
    }
  }, [location])
  useLockBodyScroll(isNavOpen)
  return (
    <>
      <Header id="head">
        <Container fluid style={{ height: `100%` }}>
          <Row style={{ height: `100%` }} justify="between">
            <Col xs={3} className="col-flex">
              <MotionLink
                animate={{
                  x: isNavOpen ? -16 : 0,
                  opacity: isNavOpen ? 0 : 1,
                  transition: {
                    duration: 0.15,
                  },
                }}
                className="logo-link"
                aria-label="Navigate to PAKD Homepage"
                to="/"
              >
                <NavLogo
                  navLogoColor={navigationColor}
                  isSetNavSVG={isSetNavSVG}
                  setNavSVG={setNavSVG}
                />
              </MotionLink>
            </Col>
            <Col xs={3} className="col-flex">
              <Menu
                navigationColor={navigationColor}
                onClick={() => setNavOpen(!isNavOpen)}
                style={{ margin: `0 auto` }}
                isNavOpen={isNavOpen}
                setNavSVG={setNavSVG}
              />
            </Col>
          </Row>
        </Container>
      </Header>
      <AnimatePresence>
        {isNavOpen && (
          <MenuNav
            key="menunav"
            variants={variants}
            initial="closed"
            animate={isNavOpen ? `open` : `closed`}
            exit="closed"
            transition={{ damping: 300 }}
            className="navmodal"
          >
            <motion.ul variants={ulVariants} key="menuul">
              <motion.li key="logo" variants={liVariants}>
                <PAKDLogo
                  to="/"
                  activeClassName="activeSVG"
                  onClick={() => setNavOpen(!isNavOpen)}
                >
                  <LogoAnim width="70%" isNavOpen={isNavOpen} speed={1.66} />
                </PAKDLogo>
              </motion.li>
              {links.map(link => (
                <motion.li key={link.key} variants={liVariants}>
                  <motion.h6
                    style={{
                      margin: 0,
                      textAlign: `center`,
                      color: `#FED9B7`,
                    }}
                  >{`NO.0${link.key}`}</motion.h6>
                  <AnimationLink
                    to={link.link}
                    onClick={() => setNavOpen(false)}
                    activeClassName="activenavhoverdiv"
                  >
                    {link.name}
                  </AnimationLink>
                  <HoverDiv className="hoverdiv" />
                </motion.li>
              ))}
              <motion.li key="wslogo" variants={liVariants}>
                <WorkshopLink
                  to="/"
                  activeClassName="activeSVG"
                  onClick={() => setNavOpen(!isNavOpen)}
                />
              </motion.li>
            </motion.ul>
          </MenuNav>
        )}
      </AnimatePresence>
    </>
  )
}

export default Navigation

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500000000;
  height: ${rhythm(3.5)};
  padding: ${rhythm(0.33)};
`
const MotionLink = styled(motion.custom(AnimationLink))``
const MenuNav = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500000;
  background: ${props => props.theme.brand.primaryalt};
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  li {
    padding: 0;
    margin: 0 0 ${rhythm(1.5)};
    font-size: ${rhythm(1.15)};
    position: relative;
    a {
      color: white;
      text-decoration: none;
      position: relative;
      transition: all 0.33s cubic-bezier(0.15, 0.65, 0.4, 1);
      z-index: 10000;
      opacity: 0.66;
    }
    .hoverdiv {
      width: 0%;
      transition: width 0.33s cubic-bezier(0.15, 0.65, 0.4, 1);
    }
    &:hover {
      a {
        opacity: 1;
      }
      .hoverdiv {
        width: 100%;
        transition: width 0.33s cubic-bezier(0.15, 0.65, 0.4, 1);
      }
    }
    .hoverdiv {
    }
  }
`

const HoverDiv = styled(motion.div)`
  content: "";
  height: ${rhythm(1 / 12)};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -5;
  background: ${props => props.theme.brand.orangered};
  transition: ${props => props.theme.transitions.circ};
`
const PAKDLogo = styled(AnimationLink)`
  grid-area: logo;
  margin: 0 auto;
  place-self: end;
  margin-bottom: ${rhythm(1)};
  .lottiewrapper {
    div {
      display: flex !important;
    }
    max-width: 72px;
    /* ${below.s`
    max-width:72px;`} */
  }
  &:hover {
    svg * {
      opacity: 1;
      transition: ${props => props.theme.transitions.circ};
    }
  }
  svg * {
    fill: white;
    stroke: white;
    opacity: 0.75;
    transition: opacity 1s ease-in-out;
  }
`

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({
      isCaseStudy: PropTypes.bool,
      isWorkshopBlog: PropTypes.bool,
    }),
  ]),
}

Navigation.defaultProps = {
  pageContext: PropTypes.shape({
    isCaseStudy: false,
    isWorkshopBlog: false,
  }),
}
