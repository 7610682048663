import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import Lightbox from "react-spring-lightbox"
import styled from "styled-components"
import Color from "color"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { rhythm } from "../../../config/typography"
import { CloseModalBtn } from "../Modal/Modal"
import { useLockBodyScroll } from "react-use"
import { Container, Col, Row } from "react-grid-system"
import FramerLightbox from "./FramerLightbox"

const CoolLightbox = ({
  images,
  currentImageIndex,
  isOpen,
  onClose,
  goBack,
  goForward,
  modalImagePadding,
}) => {
  const Counter = () => (
    <h4
      style={{
        color: `white`,
        position: `absolute`,
        right: 0,
        top: 0,
        padding: `${rhythm(1)}`,
      }}
    >
      {currentImageIndex + 1} / {images.length}
    </h4>
  )
  const Helpers = () => (
    <Container fluid style={{ width: `100vw`, maxWidth: `800px` }}>
      <Row component={RowStyl} align="center" justify="between">
        <Col sm="content" xs={12}>
          <h6 className="noselect">
            <span className="lightboxnav"> Arrow / R+L / Drag / Swipe : </span>
            {` `}
            Next / Prev
          </h6>
        </Col>

        <Col sm="content" xs={12}>
          <h6 className="noselect">
            <span className="lightboxnav"> Ctrl + Wheel / Pinch : </span> Zoom +
            / -
          </h6>
        </Col>
        <Col sm="content" xs={12}>
          {` `}
          <h6 className="noselect">
            <span className="lightboxnav"> ESC Key : </span> Close
          </h6>
        </Col>
      </Row>
    </Container>
  )
  const BackArrow = () => (
    <FaArrowLeft
      size={`${rhythm(1)}`}
      onClick={goBack}
      color="#ffffff"
      style={{
        position: `fixed`,
        marginLeft: `${rhythm(1)}`,
        gridArea: `left`,
        zIndex: 10000,
        left: 0,
        cursor: `pointer`,
      }}
      className="ddLightboxArrows"
    />
  )
  const ForwardArrow = () => (
    <FaArrowRight
      size={`${rhythm(1)}`}
      onClick={goForward}
      color="#ffffff"
      style={{
        position: `fixed`,
        marginRight: `${rhythm(1)}`,
        gridArea: `right`,
        zIndex: 10000,
        right: 0,
        cursor: `pointer`,
      }}
      className="ddLightboxArrows"
    />
  )

  useLockBodyScroll(isOpen)
  return (
    <FramerLightbox images={images} modalImagePadding={modalImagePadding} />
  )
}

CoolLightbox.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  currentImageIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CoolLightbox

const RowStyl = styled.div`
  color: ${props => props.theme.colors.litestgrey};
  opacity: 0.8;
  margin: auto !important;
  padding: ${rhythm(1)} !important;
  * {
    outline: none !important;
  }
  span {
    color: ${props => props.theme.colors.midgrey};
  }
  h6 {
    font-size: ${rhythm(1 / 2)};
    text-align: center;
    margin: 0 auto;
  }
`
const StyledLightbox = styled(Lightbox)`
  background: ${({ theme }) =>
    Color(theme.accentColor).alpha(0.95).hsl().string()};
  * ::selection {
    background: ${({ theme }) => theme.pageContentSelectionColor};
  }
  * ::-moz-selection {
    background: ${({ theme }) =>
      new Color(theme.pageContentSelectionColor).darken(0.57).hex()};
  }
  .lightbox-image-stage {
    .lightbox-image-pager {
      .lightbox-image-container {
        .lightbox-image {
          padding: ${props => props.modalImagePadding};
          max-width: 75% !important;
          margin: auto;
        }
      }
    }
  }
`
